//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapActions, mapState } from 'vuex'
export default {
  name: 'CartPage',
  async asyncData ({ store, app }) {
    store.dispatch('Cart/actionWithCart', false)
    await store.dispatch('Cart/handlingLoading', true)

    try {
      const payloadAvailable = {
        data: {
          conditions: {
            stock: 'available_stock'
          },
          order_by: {
            updated_at: 'desc'
          },
          session_id: app.$cookies.get('session_id')
        }
      }

      const payloadUnAvailable = {
        data: {
          conditions: {
            stock: 'unavailable_stock'
          },
          order_by: {
            updated_at: 'desc'
          },
          session_id: app.$cookies.get('session_id')
        }
      }

      await store.dispatch('Cart/getCarts', payloadAvailable)
      await store.dispatch('Cart/getEmptyCarts', payloadUnAvailable)

      try {
        const payloadAddress = {
          data: {
            where: {
              addressable_id: app.$auth.$state.user.id // user id
            }
          }
        }
        await store.dispatch('User/address/getUserAddress', payloadAddress)
      } catch (error) {
        console.log(error)
      }
    } catch (error) {
      console.log(error)
    }
  },

  data () {
    return {
      breadCrumb: [
        {
          path: '/'
        },
        {
          name: 'Keranjang'
        }
      ]
    }
  },

  computed: {
    ...mapState('Cart', ['cart', 'cartEmpty']),
    ...mapState('User/address', ['userAddress']),

    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return []
      }
    }
  },

  mounted () {
    if (localStorage.cartItem) {
      localStorage.removeItem('cartItem')
    }

    if (localStorage.note) {
      localStorage.removeItem('note')
    }

    if (localStorage.getItem('voucher')) {
      localStorage.removeItem('voucher')
    }

    this.setSelectedBranch(0)
    this.setTakeInStore(false)
  },

  methods: {
    ...mapActions('Voucher', ['getVouchersByType']),
    ...mapMutations('Checkout', ['setTakeInStore', 'setSelectedBranch'])
  },

  head: () => {
    return {
      title: 'Keranjang | Paninti Store'
    }
  }
}
