//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
export default {
  data () {
    const validatorPassword = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      /* eslint-disable */
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/

      if (value === ' ') {
        this.formData[rule.field] = ''
      }
      if (input === ' ') {
        this.formData[rule.field] = value.substr(0, value.length - 1)
      }
      if (
        rule.field === 'confirm_new_password' &&
        this.onSubmit &&
        this.formData.new_password !== value
      ) {
        callback(new Error('Konfirmasi password tidak sesuai'))
      }
      if (!value.match(regex)) {
        callback(
          new Error('Password minimal berisi 1 huruf kapital dan 1 angka')
        )
      }
      callback()
    }
    return {
      onSubmit: false,
      formData: {
        current_password: '',
        new_password: '',
        confirm_new_password: ''
      },
      rules: {
        current_password: [
          {
            required: true,
            message: 'Masukan password saat ini',
            trigger: 'blur'
          },
          {
            min: 6,
            message: 'Password minimal 6 karakter',
            trigger: 'blur'
          }
        ],
        new_password: [
          {
            required: true,
            message: 'Masukan password baru',
            trigger: 'blur'
          },
          {
            min: 6,
            message: 'Password baru minimal 6 karakter',
            trigger: 'blur'
          },
          {
            validator: validatorPassword
          }
        ],
        confirm_new_password: [
          {
            required: true,
            message: 'Masukan konfirmasi password baru',
            trigger: 'blur'
          },
          {
            min: 6,
            message: 'Konfirmasi password baru minimal 6 karakter',
            trigger: 'blur'
          },
          {
            validator: validatorPassword
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('User', ['changePassword']),
    handleSubmit() {
      this.onSubmit = true
      this.$refs.editPasswordForm.validate(async valid => {
        if (valid) {
          const payload = {
            data: {
              ...this.formData
            }
          }
          try {
            const resp = await this.changePassword(payload)
            if (resp.data.status_code === 200) {
              this.$Swal.fire({
                icon: 'success',
                title: 'Pemberitahuan',
                text: 'Kata sandi berhasil diperbaharui!',
                heightAuto: false
              })
              this.$router.push('/account/profile')
            }
          } catch (error) {
            this.$Swal.fire({
              icon: 'warning',
              title: 'Pemberitahuan',
              text: error.response.data.message,
              heightAuto: false
            })
          }
        }
      })
      this.onSubmit = false
    }
  }
}
