import { render, staticRenderFns } from "./index.vue?vue&type=template&id=8b1979cc&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=8b1979cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b1979cc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,CartListProduct: require('/usr/src/app/components/home/cart/CartListProduct.vue').default,CartListEmptyProduct: require('/usr/src/app/components/home/cart/CartListEmptyProduct.vue').default,ModalDeleteCart: require('/usr/src/app/components/home/cart/ModalDeleteCart.vue').default,EmptyCart: require('/usr/src/app/components/home/cart/EmptyCart.vue').default,CartProductRecommendation: require('/usr/src/app/components/home/cart/CartProductRecommendation.vue').default,CartPaycheck: require('/usr/src/app/components/home/cart/CartPaycheck.vue').default,PopupVoucher: require('/usr/src/app/components/home/cart/PopupVoucher.vue').default,PopupDetailVoucher: require('/usr/src/app/components/home/cart/PopupDetailVoucher.vue').default,FormCreateAddress: require('/usr/src/app/components/account/address/FormCreateAddress.vue').default})
