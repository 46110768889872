//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapMutations } from 'vuex'
import panstoreMixin from '@/mixins/index'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      selected: [],
      onSubmit: false
    }
  },
  computed: {
    ...mapState('Cart', ['selectedProduct', 'isBuyNow']),
    ...mapState('User/address', ['location', 'userAddress', 'isCreated']),
    preOrderShow () {
      if (this.indexVariant === -1) {
        return this.selectedProduct.is_preorder
      }

      return !!this.selectedProduct.details[this.indexVariant].is_preorder
    },
    indexVariant () {
      if (this.getProductId()) {
        return this.selectedProduct.details.findIndex((detail) => {
          return detail.id === this.getProductId()
        })
      }
      return -1
    },
    preOrderPeriode () {
      if (this.indexVariant === -1) {
        return this.selectedProduct.preorder_period
      }

      return this.selectedProduct.details[this.indexVariant].preorder_period
    },
    defaultVariant () {
      if (
        this.selectedProduct &&
        Object.keys(this.selectedProduct).length &&
        this.selectedProduct.details
      ) {
        let variant = this.selectedProduct.details.filter((variant) => {
          return variant.main
        })[0]
        if (!variant) {
          variant = this.selectedProduct.details[0]
        }
        const tempVariant = variant.name ? variant.name.split(',') : []
        return tempVariant
      } else {
        return null
      }
    },
    onFlashsale () {
      const productId = this.getProductId()
      const data = this.selectedProduct.details.filter((variant) => {
        return variant.id === productId
      })[0]
      if (data && data.event_sale_session) {
        return data
      }
      return false
    },
    isAvailable () {
      if (this.defaultVariant) {
        const variant = this.selected.join(',')
        const selectedVariant = this.selectedProduct.details.filter((v) => {
          return variant === v.name
        })[0]
        if (selectedVariant) {
          return selectedVariant.stock
        }
      }
      return false
    },
    imageProduct () {
      const productId = this.getProductId()
      if (productId) {
        const variant = this.selectedProduct.details.filter((v) => {
          return v.id === productId
        })[0]
        let image = ''
        if (variant.files && variant.files.length) {
          image = variant.files.filter((f) => {
            return f.variant === 'thumbnail'
          })[0].value
        }
        return image
      } else {
        return this.selectedProduct.files.filter((f) => {
          return f.variant === 'thumbnail'
        })[0].value
      }
    }
  },
  watch: {
    defaultVariant () {
      this.selected = this.defaultVariant
    }
  },
  mounted () {
    const modal = document.getElementById('popupCart')

    if (modal) {
      const vm = this
      modal.addEventListener('hidden.bs.modal', (event) => {
        vm.setIsBuyNow(false)
      })
    }
  },
  methods: {
    ...mapActions('Cart', ['addCart', 'getCarts', 'getCartsNavbar', 'getEmptyCarts']),
    ...mapMutations('Cart', ['setSelectedVariant', 'setIsBuyNow']),
    getVariantLabel (variant) {
      if (variant === 'Color') {
        return 'Pilihan Warna'
      } else if (variant === 'Size') {
        return 'Pilihan Ukuran'
      } else if (variant === 'Flavor') {
        return 'Pilihan Rasa'
      } else {
        return null
      }
    },
    handleChangeColor (value) {
      this.selectedColor = value
    },
    getProductId () {
      if (this.selected && this.selected.length) {
        const variant = this.selected.join(',')
        const filteredVariant = this.selectedProduct.details.filter((v) => {
          return v.name === variant
        })

        return filteredVariant.length ? filteredVariant[0].id : ''
      }

      return ''
    },
    async handleAddToCart (direct) {
      this.onSubmit = true
      const productId = this.getProductId()
      const payload = Object.assign({
        data: {
          product_detail_id: productId,
          quantity: 1,
          session_id: this.$cookies.get('session_id')
        }
      })

      try {
        const resp = await this.addCart(payload)
        localStorage.removeItem('cartItem')
        if (resp.data.status_code === 201) {
          if (!direct) {
            await this.setSelectedVariant(productId)
            await this.handleGetCart()
            this.$refs.closeButton.click()
            this.$refs.showAlert.click()
          } else {
            const cartItem = []
            cartItem.push(resp.data.data.cart.id)
            localStorage.cartItem = JSON.stringify(cartItem)

            const route = this.$auth.loggedIn ? '/checkout' : '/login'
            this.$refs.closeButton.click()
            setTimeout(() => {
              this.$router.push(route)
            }, 200)
          }
        }
      } catch (error) {
        this.$message.error(error.response.data.message)
        this.onSubmit = false
        console.log(error)
      }
      this.onSubmit = false
    },
    handleBuyNow () {
      if (this.$auth.loggedIn) {
        if (
          this.$auth.user.main_address ||
          this.userAddress.length
        ) {
          this.handleAddToCart(true)
        } else {
          this.$Swal
            .fire({
              title: 'Anda belum memiliki alamat',
              text:
                'Silahkan tambah alamat untuk melanjutkan ke proses checkout',
              icon: 'warning',
              showCloseButton: true,
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Tambah alamat'
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$refs.closeButton.click()
                setTimeout(() => {
                  this.$refs.buttonCreateAddress.click()
                }, 200)
              }
            })
        }
      } else {
        this.$refs.closeButton.click()
        setTimeout(() => {
          this.$router.push('/login')
        }, 200)
      }
    },
    async handleGetCart () {
      let payload = {
        session_id: this.$cookies.get('session_id'),
        order_by: { created_at: 'desc' }
      }
      if (this.$route.name === 'cart') {
        payload = await Object.assign({
          ...payload,
          conditions: {
            stock: 'available_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })
        const unAvail = await Object.assign({
          ...payload,
          conditions: {
            stock: 'unavailable_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })
        try {
          await this.getEmptyCarts({ data: unAvail })
        } catch (error) {
          console.log(error)
        }
      }
      try {
        await this.getCartsNavbar({
          params: {
            session_id: payload.session_id
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    getAlert () {
      let error = ''
      const totalVar = this.selectedProduct.variant_categories.length
      this.selectedProduct.variant_categories.map((variant, index) => {
        error += `${variant.display_name} "${this.selected[index]}"`
        if (totalVar > 1) {
          if (index + 2 === totalVar) {
            error += ' dan '
          } else if (index + 1 !== totalVar) {
            error += ', '
          }
        }
      })
      return error
    },
    getPrice () {
      let price = this.selectedProduct.discounted_price
      if (this.selectedProduct.type === 'combination') {
        this.selectedProduct.details.map((detail) => {
          if (detail.id === this.getProductId()) {
            price =
              detail.discounted_price || this.selectedProduct.discounted_price
          }
        })
      }
      return price
    }
  }
}
