import { render, staticRenderFns } from "./StoreSection.vue?vue&type=template&id=7351b458&scoped=true&"
import script from "./StoreSection.vue?vue&type=script&lang=js&"
export * from "./StoreSection.vue?vue&type=script&lang=js&"
import style0 from "./StoreSection.vue?vue&type=style&index=0&id=7351b458&lang=scss&scoped=true&"
import style1 from "./StoreSection.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7351b458",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/base/Button.vue').default})
