//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations, mapState } from 'vuex'

import userAffiliateMixin from '~/mixins/user-affiliate-mixin'

export default {
  mixins: [
    userAffiliateMixin
  ],

  data () {
    return {
      isBtnWishlistHovered: false
    }
  },

  computed: {
    ...mapState('Product/ProductDetailPage', [
      'loadingToggleWishlist', 'isProductWishlisted',
      'loadingAddProductAffiliate', 'isProductAffiliated',
      'product'
    ])
  },

  methods: {
    ...mapMutations('Product/ProductDetailPage', [
      'setLoadingToggleWishlist', 'setLoadingAddProductAffiliate'
    ]),
    ...mapActions('Product/ProductDetailPage', [
      'toggleProductToWishlist', 'addProductAffiliate'
    ]),

    // Wishlist
    async handleClickBtnWishlist () {
      if (!this.userLoggedIn) {
        await this.$router.push('/login?redirect_from=' + this.$route.fullPath)
        return
      }

      try {
        this.setLoadingToggleWishlist(true)

        await this.toggleProductToWishlist({
          data: {
            product_id: [this.product.id]
          }
        })

        this.$message({
          message: `Produk berhasil ${
            !this.isProductWishlisted ? 'dihapus dari ' : 'ditambahkan ke'
          } Wishlist.`,
          type: 'success'
        })
      } catch (error) {
        console.error(error)

        this.$message({
          message: 'Terjadi kesalahan saat memproses Wishlist.',
          type: 'error'
        })
      } finally {
        this.setLoadingToggleWishlist(false)
      }
    },

    // Affiliate
    async handleClickBtnAffiliate () {
      try {
        this.setLoadingAddProductAffiliate(true)

        await this.addProductAffiliate({
          data: {
            product_ids: [this.product.id]
          }
        })

        await this.$Swal
          .fire({
            icon: 'info',
            title: 'Produk Sedang Ditambahkan',
            text: 'Mohon tunggu, sistem sedang membuat link affiliate produk yang ditambahkan',
            heightAuto: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: false,
            showConfirmButton: false
          })
      } catch (error) {
        console.log(error)

        await this.$Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Mohon maaf terjadi kesalahan, periksa kembali koneksi internet Anda dan coba beberapa saat lagi.',
          confirmButtonText: 'Kembali'
        })
      } finally {
        this.setLoadingAddProductAffiliate(false)
      }
    }
  }
}
